import { axios } from "./instance";

const API_URL = process.env.NEXT_PUBLIC_API_LINK;

export const adminLogin = async (data) => {
  const res = await axios.post(`${API_URL}/api/admin/login_admin`, data)
  return res;
}

export const getAllAdmins = async (data) => {
  const res = await axios.get(`${API_URL}/api/admin/get_admin?search=${data.search || ""}&page=${data.page + 1 || "1"}&limit=${data.rowsPerPage || "10"}`);
  return res.data;
};

export const getOneAdmin = async (id) => {
  const res = await axios.get(`${API_URL}/api/admin/get_admin/${id}`);
  return res.data;
};

export const addAdmin = async (data) => {
  const res = await axios.post(`${API_URL}/api/admin/create_admin`, data);
  return res.data;
};

export const updateAdmin = async (data) => {
  let adminData;
  if (data?.password !== "") {
    adminData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      password: data?.password,
      isSuperAdmin: data?.isSuperAdmin,
      isBlocked: data?.isBlocked,
    }
  } else {
    adminData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      isSuperAdmin: data?.isSuperAdmin,
      isBlocked: data?.isBlocked,
    }
  }

  const res = await axios.put(`${API_URL}/api/admin/update_admin/${data?.adminId}`, adminData);
  return res.data;
};

export const deleteAdmin = async (id) => {
  const res = await axios.delete(`${API_URL}/api/admin/delete_admin/${id}`);
  return res.data;
};

export const deleteMultipleAdmin = async (id) => {
  const data = {
    ids: id
  }
  const res = await axios.delete(`${API_URL}/api/admin/delete_multiple_admin`, { data });
  return res.data;
};