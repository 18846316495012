import * as Axios from 'axios';
import Router from 'next/router';

const https = require('https')
const getToken = () => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('accessToken')
    }
}

export const axios = Axios.create({
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    }),
    'headers': {
        // 'x-api-key': process.env.NEXT_PUBLIC_X_API_KEY,
        'Authorization': `Bearer ${getToken()}`,
        'Accept': 'application/json',
    }
})

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('isSuperAdmin');
            Router.push('/authentication/login');
        }
        return Promise.reject(error);
    }
);