import { createResourceId } from '../utils/create-resource-id';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from '../utils/jwt';
import { wait } from '../utils/wait';
import { getAllAdmins } from '../_api/admin';

const players = [
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
    email: 'demo@noonan.io',
    name: 'Anika Visser',
    password: 'Password123!',
    plan: 'Premium'
  }
];

class AuthApi {
  async login({ email, password }) {

    return new Promise(async(resolve, reject) => {
      try {
        // Find the user
        const admins = await getAllAdmins();

        const user = admins.find((_user) => _user.email === email);

        if (!user || (user.password !== password)) {
          reject(new Error('Please check your email and password'));
          return;
        }

        // Create the access token
        const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async register({ email, name, password }) {
    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user = admins.find((_user) => _user.email === email);

        if (user) {
          reject(new Error('Player already exists'));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: undefined,
          email,
          name,
          password,
          plan: 'Standard'
        };

        admins.push(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken, userID) {
    return new Promise(async(resolve, reject) => {

      try {
        const users = await getAllAdmins();

        const user = users.find((_user) => _user._id === userID);
       
        if (accessToken === undefined) {
          reject(new Error("Auhorization Error"));
          return;
        }
        if (accessToken === "NULL") {
          reject(new Error("Auhorization Error"));
          return;
        }
        if (accessToken === null) {
          reject(new Error("Auhorization Error"));
          return;
        }
        if (isExpired(accessToken)) {
          reject(new Error("Auhorization Error"));
          return;
        } else {
          resolve(accessToken);
        }

      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();
