import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__fake-api__/auth-api';
import { adminLogin } from '../_api/admin';
import { useRouter } from "next/router";

// var ActionType;
// (function (ActionType) {
//   ActionType['INITIALIZE'] = 'INITIALIZE';
//   ActionType['LOGIN'] = 'LOGIN';
//   ActionType['LOGOUT'] = 'LOGOUT';
//   ActionType['REGISTER'] = 'REGISTER';
// })(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  token: '',
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, token } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token: token
    };
  },
  LOGIN: (state, action) => {
    const { user, token } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      token: token
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const isSuperAdmin = window.localStorage.getItem("isSuperAdmin")
        const id = window.localStorage.getItem("id")

        if (accessToken) {
          // const user = await authApi.me(accessToken);
          // const response = await authApi.me(accessToken, id);
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              token: accessToken,
              id: id,
              // user
            }
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              token: '',
              id: '',
              // user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            // user: null
          }
        });
      }
    };

    initialize();
  }, [router]);


  const login = async (email, password) => {

    const response = await adminLogin({ email: email, password: password })

    if (response?.data?.status === "Success") {
      localStorage.setItem("accessToken", response?.data?.token);
      localStorage.setItem("isSuperAdmin", response?.data?.data?.isSuperAdmin);
      localStorage.setItem("id", response?.data?.data?.id);
      dispatch({
        type: "LOGIN",
        payload: {
          user: {},
          token: response?.data?.Token
        },
      });
      return ({ success: true, message: response?.data?.message })
    } else {
      return ({ success: false, message: response?.data?.message })
    }
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isSuperAdmin');
    dispatch({ type: "LOGOUT" });
    // dispatch({ type: ActionType.LOGOUT });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: ActionType.REGISTER,
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
